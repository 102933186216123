<template>
  <div>
    <ul>
      <li v-for="(item) in goodsList" :key="item.id" @click="toProductDeatil(item.id)">
        <img :src="item.list_pic_url" style="display:block" width="100%">
        <div class="van-ellipsis">{{item.name}}</div>
        <div class="price">{{item.retail_price | RMBformat}}</div>
      </li>    
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc:"http://cdnoss.ksyt.com.cn/wxImages/assets/images/logo_shegong.png"
 
    }
  },
  props: ["goodsList"],
  methods: {
    toProductDeatil(id) {
      this.$router.push("/productDetail?id="+id);
      setTimeout(() => {
        this.$router.go(0);
      }, 10);
    }
  },
}
</script>
 
<style lang = "less" scoped>
  ul {
    padding: .1rem 2%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 49%;
      margin-bottom: .1rem;
      background: #fff;
      text-align: center;
      line-height: .3rem;

      .price {
        color: rgb(197, 77, 77);
      }
    }
  }
</style>