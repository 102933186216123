<template>
  <div>
    <van-dropdown-menu>
      <van-dropdown-item disabled title="综合"  />
      <van-dropdown-item @change="priceChange"  title="价格" v-model="priceVal" :options="option2" />
      <van-dropdown-item @change="learningChange" title="分类" v-model="learningVal" :options="filterLearning" />
    </van-dropdown-menu>


    <van-empty v-if="goodsList.length == 0" image="search" description="没有搜索的商品" />
    <Products v-else :goodsList="goodsList" />
  </div>
</template>

<script>

import Products from '@/components/Products'

export default {
  data () {
    return {
      priceVal: "",
      learningVal: "",  // 这个的值是哪一项的value就表示哪一项被勾中
      option2: [
        { text: '价格由高到低', value: 'desc' },
        { text: '价格由低到高', value: 'asc' },
      ],
      
    }
  },
  beforeUpdate() {
      // 数据挂在完毕,遍历filterLearning  看那一项的checked为true,
      // 把为true的this.learningVal改为它的value值,这样就勾选上了
      this.filterLearning.map(item => {
        // 如果item的checked为true,就改this.learningVal
        if(item.checked) {
          this.learningVal = item.value
          return
        }
      })
  },
  methods: {
    learningChange(value) {
      // console.log(value); // value就是id
      this.$emit("learningChange", value)
    },
    priceChange(value) {
      console.log(value);
      this.$emit("priceChange", value)

    }
  },
  props: ["filterLearning", "goodsList"],
  components: {
    Products
  }
}
</script>
 
<style lang = "less" scoped>
  
</style>