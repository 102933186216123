<template>
    <div class="project">

        <van-action-sheet v-model:show="show" duration="100" @cancel="onCancel" title="选择考试">
            <div class="content">
                <template v-for="item in cateList">
                    <van-cell :title="item.cate_name" class="title"/>
                    <template v-for="item2 in item.data" >
                        <van-button round v-if="item2.id == subjectId" type="info" @click="onSubject(item.id,item2.id,item2.title)">{{item2.year_time}}{{item2.title}}</van-button>
                        <van-button round v-else class="defult" @click="onSubject(item.id,item2.id,item2.title)">{{item2.year_time}}{{item2.title}}</van-button>
                    </template>
                </template>

                <!--                <van-cell title="执业医师" class="title"/>-->
                <!--                <van-button round class="defult">临床执业医师</van-button>-->
                <!--                <van-button round class="defult">临床助理医师</van-button>-->
                <!--                <van-button round class="defult">中医执业医师</van-button>-->
                <!--                <van-button round class="defult">中医助理医师</van-button>-->
                <!--                <van-button round class="defult">中西医执业医师</van-button>-->
                <!--                <van-button round class="defult">中西医助理医师</van-button>-->
                <!--                <van-button round class="defult">乡村全科医师</van-button>-->
                <!--                <van-cell title="其他考试" class="title"/>-->
                <!--                <van-button round type="info">护士资格</van-button>-->
                <!--                <van-button round class="defult">护师职称</van-button>-->
                <!--                <van-button round class="defult">健康管理师</van-button>-->
            </div>
        </van-action-sheet>
    </div>
    <!--    <el-button type="info">测试element-ui是否成功导入</el-button>-->
</template>

<script>
    // console.log(process.env.VUE_APP_BASE_API)
    let redirectUrl;
    import {onCateList,onCateList2,groupSubjectPost,examinationTime} from "@/request/api";
    import Qs from "qs";
    import wx from "weixin-js-sdk";
    export default {
        beforeRouteEnter(to, from, next) {
            console.log(to);
            console.log(from);
            // console.log(from.name);
            if (to.query && to.query.redirect) {
                redirectUrl = to.query.redirect
            } else {
                redirectUrl = 'Index'
            }
            next();
        },
        inject:['reload'],
        data() {
            return {
                show: '',
                cateList: [],
                groupid: this.getGroupId(),
                subjectId: this.getSubjectId()
            }
        },
        methods: {
            onCancel() {
                // console.log("onClose");
                // this.$router.push("/home")
                this.$router.go(-1)
                // this.$store.commit("changeIsShowPopupShow", false)
            },
            onSubject(groupId,subjectId,cate_name) {
                let that = this;
                let token = localStorage.getItem("token");
                if(token) {
                    groupSubjectPost({groupId,subjectId})
                        .then(res => {
                            if (res.data.code == 1) {
                                localStorage.setItem("groupId",groupId);
                                localStorage.setItem("subjectId",subjectId);
                                localStorage.setItem("groupName",cate_name);
                                // that.$parent.homeList();
                                // that.$parent.examinationTime();
                                that.$parent.groupName = (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择');
                                that.$router.push({
                                    name:redirectUrl,
                                    params:{

                                    }
                                })
                            } else {
                                Vue.prototype.$toast("请求错误，请重试")
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                        })
                } else {
                    localStorage.setItem("groupId",groupId);
                    localStorage.setItem("subjectId",subjectId);
                    localStorage.setItem("groupName",cate_name);
                    // that.$parent.homeList();
                    // that.$parent.examinationTime();
                    that.$parent.groupName = (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择');
                    that.$router.push({
                        name:(redirectUrl ? redirectUrl : 'Index'),
                        params:{

                        }
                    })
                }
                that.reload();
            }
        },
        created() {
            let token = this.getToken();
            if(token) {
                onCateList2()
                    .then(res => {
                        if (res.data.code == 1) {
                            this.cateList = res.data.data;
                        } else {
                            Vue.prototype.$toast("请求错误，请重试")
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            } else {
                let branchId = this.getBranch();
                onCateList({branchId})
                    .then(res => {
                        if (res.data.code == 1) {
                            this.cateList = res.data.data;
                        } else {
                            Vue.prototype.$toast("请求错误，请重试")
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            }

        },
        components: {},
        mounted() {
        },
    }
</script>

<style lang="less">

    @import '~@/assets/styles/common.less';

    .project {
        width: 100%;
        height: 100%;
        position: fixed;

        z-index: 99999;
        top: 0;
        left: 0;
        // background-color: #e7e9ed;
    }
</style>